
function Tail() {
    return (
      <footer>
        <div className='tail-container'>
          <div className='container-center top-border'>
            <p> Welcome to use Mongosteen Tech. v{process.env.REACT_APP_VERSION}</p>
            <p>Email us: <a href="mailto:service@mangosteen.one">service@mangosteen.one</a></p>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Tail;
  