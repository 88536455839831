import { useEffect, useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';


function AudioPlayer(props) {
  const audioRef = useRef();
  const [audioSrc, setAudioSrc] = useState(props.src);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('ended', handleAudioEnd);

    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('ended', handleAudioEnd);
    }
  }, []);

  useEffect(() => {
    // console.log('update audio source', props.src);
    setAudioSrc(props.src);
  }, [props.src]);

  useEffect(() => {
    if (audioSrc != '') {
      audioPlay();
    }
  }, [audioSrc]);

  const updateTime = () => {
    const audio = audioRef.current;

    setCurrentTime(audio.currentTime);
    setDuration(audio.duration);
  }

  const handleAudioEnd = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  }

  function audioPlay() {
    const audio = audioRef.current;

    audio.play();
    setIsPlaying(true);
  }

  function audioPause() {
    const audio = audioRef.current;

    audio.pause();
    setIsPlaying(false);
  }

  function handleSeek(event) {
    console.log('seek audio');
    const audio = audioRef.current;
    const newTime = event.target.value;
    audio.currentTime = newTime;
    setCurrentTime(audio.currentTime);
  }

  return (
    <div>
      <audio ref={audioRef} src={audioSrc} />

      <div className='audio-control-group'>
        {isPlaying ? (
          <Button variant="outline-secondary" onClick={(event) => { audioPause() }} disabled={audioSrc == ''}>
            <Image className='audio-btn-play' src="/images/pause.png" rounded />
          </Button>
        ) : (
          <Button variant="outline-secondary" onClick={(event) => { audioPlay() }} disabled={audioSrc == ''}>
            <Image className='audio-btn-play' src="/images/play.png" rounded />
          </Button>
        )}

        <input className='audio-progress-bar'
          type="range"
          value={currentTime}
          max={duration || 0}
          onChange={handleSeek}
          disabled={audioSrc == ''}
        />
      </div>
    </div>
  );
}

export default AudioPlayer;
