import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import configData from '../config.json';

import Head from "../components/Head";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ConfigurationPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('my email');
  const [balance, setBalance] = useState(0);
  const [nickname, setNickname] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [language, setLanguage] = useState('en');

  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState('20');

  const navigate = useNavigate();
  const savedName = localStorage.getItem('nickname');
  const userId = localStorage.getItem('userid');

  const initLanguage = () => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    } else {
      if (navigator.language === 'zh-CN' || navigator.language === 'zh-cn') {
        setLanguage('cn');
      } else {
        setLanguage('en');
      }
    }
  }

  const apiGetUserInfo = () => {
    fetch(configData.API_URL + `/user/check/${userId}`, 
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          setEmail(data.user.email);
          setBalance(0);  // data.user.balance
          setNickname(data.user.firstName ? data.user.firstName : data.user.username);
          setMobile(data.user.mobile);
        } else {
          navigate('/login');
        }
      })
      .catch(error => console.log(error));
  }

  const apiChangePassword = () => {
    const requestData = {
      userId: userId,
      oldPassword: password,
      newPassword: newPassword,
    };

    fetch(configData.API_URL + `/user/change-password`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'manual',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      alert(data.message);
    })
    .catch(error => {
      console.log(error);
    });
  }

  const apiChangeNickname = () => {
    const requestData = {
      userId: userId,
      nickname: nickname,
    };

    fetch(configData.API_URL + `/user/change-nickname`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'manual',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      alert(data.message);
      if (data.success) {
        localStorage.setItem('nickname', nickname);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    initLanguage();
    setNickname(savedName);
    apiGetUserInfo();
  }, []);

  const onSaveNicknameClick = () => {
    apiChangeNickname();
  }

  const onNicknameChange = (e) => {
    setNickname(e.target.value);
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const onNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  }

  const onNewPasswordAgainChange = (e) => {
    setNewPasswordAgain(e.target.value);
  }

  const onSavePasswordClick = () => {
    if (newPassword !== newPasswordAgain) {
      alert('The new password is not the same as the new password again.');
      return;
    }
    if (newPassword.length < 6) {
      alert('The new password is too short.');
      return;
    }
    apiChangePassword();
  }

  const onLanguageChange = (e) => {
    setLanguage(e.target.value);
  }

  const onSaveLanguageClick = () => {
    localStorage.setItem('language', language);
    window.location.reload();
  }

  const onMobileChange = (e) => {
    setMobile(e.target.value);
  }

  const onRechargeClick = () => {
    handleShow();
  }

  const onAmountChange = (e) => {
    if (parseFloat(e.target.value) < 2) {
      setAmount('2');
    } else {
      setAmount(e.target.value);
    }
  }

  const handleClose = () => {
    setShow(false);
  }
  const handleConfirm = () => {
    setShow(false);
    let url = '/payment?amount=' + amount + '&source=AI-RECHARGE&session_id=0&csrf_token=';
    // console.log(url);
    navigate(url);
  }
  const handleShow = () => setShow(true);

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center' style={{maxWidth: '850px'}}>

        <div className="configure-block-title">
            <h4>{t('label_reg_email')}</h4>
          </div>
          <div className="configure-block-content">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon">e-mail</InputGroup.Text>
              <Form.Control
                placeholder=""
                aria-label="Email"
                aria-describedby="basic-addon"
                type="text"
                value={email}
                disabled
              />
            </InputGroup>
          </div>

          <div className="configure-block-title">
            <h4>{t('label_balance')}</h4>
          </div>
          <div className="configure-block-content">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon">{t('label_balance')}</InputGroup.Text>
              <Form.Control
                placeholder=""
                aria-label="Balance"
                aria-describedby="basic-addon"
                type="text"
                value={balance}
                disabled
              />
              <Button variant="success" id="btn-recharge" onClick={onRechargeClick}> 充值 </Button>
            </InputGroup>
          </div>

          <div className="configure-block-title">
            <h4>{t('label_nickname')}</h4>
          </div>
          <div className="configure-block-content">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon">{t('label_nickname')}</InputGroup.Text>
              <Form.Control
                placeholder=""
                aria-label="NickName"
                aria-describedby="basic-addon"
                type="text"
                value={nickname}
                onChange={onNicknameChange}
              />
              <Button variant="success" id="btn-save" onClick={onSaveNicknameClick}> {t('btn_save')} </Button>
            </InputGroup>
          </div>

          <div className="configure-block-title">
            <h4>{t('label_mobile')}</h4>
          </div>
          <div className="configure-block-content">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon">{t('label_mobile')}</InputGroup.Text>
              <Form.Control
                placeholder=""
                aria-label="Mobile"
                aria-describedby="basic-addon"
                type="text"
                value={mobile}
                onChange={onMobileChange}
                disabled
              />
              {/* <Button variant="success" id="btn-save" onClick={onSaveMobileClick}> {t('btn_save')} </Button> */}
            </InputGroup>
          </div>

          <div className="configure-block-title">
            <h4>{t('label_change_passwd')}</h4>
          </div>
          <div className="configure-block-content">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"> {t('label_old_passwd')} </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder=""
                  aria-label="CurrentPassword"
                  aria-describedby="basic-addon1"
                  value={password}
                  onChange={onPasswordChange}
                />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon2"> {t('label_new_passwd')} </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder=""
                  aria-label="NewPassword"
                  aria-describedby="basic-addon2"
                  value={newPassword}
                  onChange={onNewPasswordChange}
                />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text id="basic-addon3"> {t('label_new_passwd')} </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder=""
                  aria-label="NewPassword"
                  aria-describedby="basic-addon3"
                  value={newPasswordAgain}
                  onChange={onNewPasswordAgainChange}
                />
            </InputGroup>
            
            <Button variant="success" style={{marginTop: '10px'}} onClick={onSavePasswordClick}>{t('btn_save_password')}</Button>
          </div>

          <div className="configure-block-title">
            <h4>{t('label_language')}</h4>
          </div>
          <div className="configure-block-content">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon4"> {t('label_language')} </InputGroup.Text>
                <Form.Select aria-label="Default select example" value={language} onChange={onLanguageChange}>
                  <option value="en">英文</option>
                  <option value="cn">中文</option>
                </Form.Select>
                <Button variant="success" id='btn-start' onClick={onSaveLanguageClick}>{t('btn_save')}</Button>
            </InputGroup>
          </div>

        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='h5'>Recharge your account, at least $20 each time.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1"> CAD $ </InputGroup.Text>
            <Form.Control
              placeholder="amount"
              aria-label="amount"
              aria-describedby="basic-addon1"
              type="number"
              value={amount}
              onChange={onAmountChange}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default ConfigurationPage;
