import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import MyPaypal from '../components/MyPaypal';
import Head from '../components/Head';
import Tail from "../components/Tail";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function PaymentPage() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const csrfToken = params.get('csrf_token');
  const sessionId = params.get('session_id');
  const amount = params.get('amount');
  const source = params.get('source');

  const userId = localStorage.getItem('userid');

  function onPaypalCompleted(success, msg) {
    console.log(msg);
    if (success) {
      setTimeout(() => {
        if (source === 'AI-RECHARGE') {
          navigate('/account');
        } else if (source === 'IDPHOTO-ONETIME') {
          navigate('/create?country=&photo=&session_id=' + sessionId);
        }
      }, 3000);

      setShow(true);
    } else {
      alert(msg);
    }
  }

  return (
    <div>
      <Head title="Payment" />

      <div className='main-container'>
        <div className='container-center' style={{maxWidth: '700px'}}>

          <div className="configure-block-title">
            <h5> Payment of ${amount} </h5>
          </div>
          <div className="configure-block-content">
          </div>
  
          <MyPaypal sessionId={sessionId} csrf={csrfToken} userId={userId} amount={amount} source={source}
            onCompleted={onPaypalCompleted} />
        </div>
      </div>

      <ToastContainer className="p-3" position={'middle-center'} style={{ zIndex: 1 }}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg='success'>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Payment</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className={'text-white'}>Payment successful.</Toast.Body>
        </Toast>
      </ToastContainer>

      <Tail />
    </div>
  );
}

export default PaymentPage;
