import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import configData from '../config.json';
import { useTranslation } from 'react-i18next';

import Head from "../components/Head";
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

import AudioPlayer from '../components/AudioPlayer';


function MyTutorPage() {
  const { t } = useTranslation();
  const [isSessionOpen, setIsSessionOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const divRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);

  const [topicId, setTopicId] = useState('');
  const [history, setHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [audioSrc, setAudioSrc] = useState('');
  const [isOccupancy, setIsOccupancy] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const userId = localStorage.getItem('userid');

  const apiGetMessageByTopic = (topicId, from) => {
    let url = `/tutor/messages/?topicId=${topicId}`;
    if (from == 'Sumup') {
      url = `/tutor/messages/?topicId=${topicId}`;
    }
    fetch(configData.API_URL + url,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          const newHistory = [];
          data.messages.forEach(item => {
            const req = {
              msgId: item._id,
              role: item.request.role,
              content: item.request.content,
              duration: 0
            };
            newHistory.push(req);
            const res = {
              msgId: item._id,
              role: item.response.role,
              content: item.response.content,
              duration: item.duration
            };
            newHistory.push(res);
          });
          setHistory(newHistory);
          setTopicId(topicId);
          setMessage('');
        } else {
          console.log(data);
        }
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    // const querySessionId = queryParams.get('sessionId');
    // console.log("query session id:", querySessionId);

    // if (querySessionId) {
    //   // handle request from a sum-up page
    //   apiGetMessageByTopic(querySessionId, 'Sumup');
    // } else {
      const sessionTopicId = sessionStorage.getItem('currentTopicId');
      if (sessionTopicId) {
        apiGetMessageByTopic(sessionTopicId, 'EnglishTutor');
      }
    // }

    return () => {
      window.removeEventListener('resize', handleResize);
      sessionStorage.setItem('currentTopicId', '');
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [history]);

  const apiGetAudioByMessageId = (messageId) => {
    fetch(configData.API_URL + `/tutor/message/audio/${messageId}`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.success) {
          setAudioSrc(data.audio.url);
        } else {
          console.log(data);
        }
      })
      .catch(error => console.log(error));
  }

  function apiChatMessage(question) {
    const requestData = {
      topicId: topicId,
      topicType: 'English',
      question: question,
    };

    fetch(configData.API_URL + '/tutor/chat', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.success) {
          setTopicId(data.data.topicId);
          startQuestionSteam(data.data.topicId, data.data.chatId);
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  function startQuestionSteam(topicId, msgId) {
    let response = '';
    const apiUrl = configData.API_URL + '/tutor/response/' + topicId;
    var source = new EventSource(apiUrl);

    source.addEventListener('message', function (event) {
      response += event.data;
      // console.log(event.data);
      const newHistory = [...history,
      { msgId: msgId, role: 'user', content: message, duration: 0 },
      { msgId: msgId, role: 'assistant', content: response, duration: 0 }
      ];
      setHistory(newHistory);
    });

    source.addEventListener('end', function (event) {
      // console.log('End, duration: ', event.data);
      source.close();
      setMessage('');
      const newHistory = [...history,
      { msgId: msgId, role: 'user', content: message, duration: 0 },
      { msgId: msgId, role: 'assistant', content: response, duration: event.data }
      ];
      setHistory(newHistory);
      setIsOccupancy(false);
      disableInput(false);
    });

    source.addEventListener('error', function (event) {
      console.log('An error occurred:', event.data);
      source.close();
      setIsOccupancy(false);
      disableInput(false);
    });
  }

  function disableInput(disabled) {
    const input = document.getElementById('chatgpt-textarea');
    input.disabled = disabled;
    const button = document.getElementById('button-send');
    button.disabled = disabled;
  }

  function sendQuestion(question) {
    disableInput(true);
    const newHistory = [...history,
    { role: 'user', content: question, duration: 0 },
    { role: 'assistant', content: 'Computing...', duration: 0 }
    ];
    setHistory(newHistory);
    setIsOccupancy(true);
    apiChatMessage(question);
  }

  function onSendWordClick() {
    if (message === '') {
      alert('Please enter a word or phrase');
      return;
    }
    const question = `Please explain the following English words or phrases, "${message}"`;
    sendQuestion(question);
  }

  function onSendTranslateClick() {
    if (message === '') {
      alert('Please enter a sentence in your language');
      return;
    }
    const question = `Please express the following sentences in English: ${message}`;
    sendQuestion(question);
  }

  function onSendFreeTalkClick() {
    if (message === '') {
      alert('Please enter something you want to learn');
      return;
    }
    sendQuestion(message);
  }

  function onNewTopic() {
    setTopicId('');
    setHistory([]);
    setMessage('');
  }

  function autoResize() {
    var textArea = document.getElementById("chatgpt-textarea");
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  }

  const scrollToBottom = () => {
    const div = divRef.current;
    div.scrollTop = div.scrollHeight;
  }

  function onVoiceClick(messageId) {
    // console.log('Message ID', messageId);
    setModalShow(true);
    apiGetAudioByMessageId(messageId);
  }

  const toggleSessionList = () => setIsSessionOpen(!isSessionOpen);

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center screen-height'>
          <div className='sub-right-container'>

            <Collapse in={isSessionOpen}>
              <div className='sub-left d-sm-block'>
                <div>
                  <img className='tutor-avatar' src='/images/english-tutor.png' alt=''></img>
                  <p></p>
                  <p>Hi, I am your English tutor</p>
                  <p>Level: Senior</p>
                </div>
              </div>
            </Collapse>

            <div className='sub-right flex-grow-1'>
              {windowWidth < 576 && (
                <Button className='btn-menu' variant='success' onClick={toggleSessionList}>
                  {isSessionOpen ? '<' : '>'}
                </Button>
              )}

              {/* -- Chat history -- */}
              <div ref={divRef} className='chat-box-container'>
                {!topicId && (
                  <div className='chat-instruction'>
                    <p>Instructions:</p>
                    <p>1. Word/Phrase Learning: Enter a word or phrase, then click "Send" - "Word explanation"</p>
                    <p>2. Teaching you how to express in English: Use your language to input whatever you want to say, then click "Send" - "Say it in English"</p>
                    <p>3. Free talk: Enter any content or question you want to learn, then click "Send" - "Free Talk"</p>
                  </div>
                )}
                {history.map((msg, index) => {
                  if (msg['role'] !== 'assistant') {
                    return (
                      <div className='chat-box chat-box-me' key={index}>
                        <div className='chat-box-image-text'>
                          <p>Me</p>
                        </div>
                        <div className='chat-box-text'>
                          <p className='break-line'>{msg['content']}</p>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className='chat-box chat-box-ai' key={index}>
                        <div>
                          <img className='chat-box-image' src='/images/head-logo.png' alt='' />
                        </div>
                        <div className='chat-box-text'>
                          <p className='break-line'>{msg['content']}</p>
                          <p className='chat-box-text-time'>
                            {msg['duration'] === 0 ? 'Loading...' : msg['duration'] + 'secs'}
                            {!isOccupancy && (
                              <Image className='tutor-btn-voice' src="/images/voice.png" rounded onClick={() => { onVoiceClick(msg['msgId']); }} />
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })
                }
              </div>

              {/* 输入区域 */}
              <div className='chat-input-container flex-grow-1'>
                <InputGroup className="mb-3">
                  {topicId && (
                    <Button variant="outline-success" id="button-new" style={{ width: '60px' }} onClick={onNewTopic}>
                      {t('tutor_btn_new')}
                    </Button>)}
                  <Form.Control
                    as="textarea"
                    placeholder="Enter your topic or question..."
                    aria-label="Write your message here..."
                    aria-describedby="basic-addon2"
                    className="textarea-input"
                    id="chatgpt-textarea"
                    value={message}
                    onInput={autoResize}
                    onChange={onMessageChange}
                  />
                  <DropdownButton id="button-send" title="Send" variant="outline-success" drop='up-centered'>
                    <Dropdown.Item as="button" onClick={onSendWordClick}>Word explanation</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={onSendTranslateClick}>Say it in English</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={onSendFreeTalkClick}>Free Talk</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Tail />

      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ width: '350px', marginLeft: 'auto', right: '10px' }}>
        <Modal.Header>
          <h6>{audioSrc === '' ? 'Generating audio, please wait' : 'Audio is ready'}</h6>
        </Modal.Header>
        <Modal.Body>
          <AudioPlayer src={audioSrc} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => {
            setModalShow(false);
            setAudioSrc('');
          }} variant="outline-success">Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MyTutorPage;
