import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import configData from '../config.json';

import Head from "../components/Head";
import Table from 'react-bootstrap/Table';


function AdminPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registeredUsers, setRegisteredUsers] = useState(0);
  const [userList, setUserList] = useState([]);

  const apiGetAdminData = () => {
    fetch(configData.API_URL + `/user/admin/dashboard/`, { redirect: 'manual' })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else if (response.type === 'opaqueredirect') {
        navigate('/login');
      } else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      // console.log(data);
      if (data.success) {
        setRegisteredUsers(data.registeredUsers);
        setUserList(data.userList);
      }
    })
    .catch(error => console.log(error));
  }

  useEffect(() => {
    apiGetAdminData();
  }, []);

  return (
    <div>
      <Head />
      <div className='main-container'>
        <div className='container-center' style={{maxWidth: '850px'}}>

          <div className="configure-block-title">
            <h4>{'Registered User: ' + registeredUsers}</h4>
          </div>
          <div className="configure-block-content">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Joined Time</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.username}</td>
                    <td>{user.date_joined}</td>
                    <td>{user.is_active ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

        </div>
      </div>

    </div>
  );
}

export default AdminPage;
