import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import configData from '../config.json';


function MyPaypal(props) {

  const FUNDING_SOURCES = [
    FUNDING.PAYPAL,
    FUNDING.PAYLATER,
    FUNDING.VENMO,
    FUNDING.CARD
  ];

  return (
    <div>

      {
        FUNDING_SOURCES.map(fundingSource=>{
          return (
            <PayPalButtons
              fundingSource={fundingSource}
              key={fundingSource}
              
              style={{
                layout: 'vertical',
                shape: 'rect',
                color: (fundingSource == window.paypal.FUNDING.PAYLATER) ? 'gold' : '',
              }}

              createOrder={async (data, actions) => {
                try {
                  const orderData = new FormData();
                  orderData.append('user_id', props.userId);
                  orderData.append('session_id', props.sessionId);
                  orderData.append('money', props.amount);
                  orderData.append('source', props.source);

                  const response = await fetch(configData.API_URL + "/payment/paypal/create-order/", {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                      'X-CSRFToken': props.csrf
                    },
                    body: orderData
                  });

                  const details = await response.json();
                  return details.id;
                } catch (error) {
                  console.error(error);
                  // Handle the error or display an appropriate error message to the user
                }
              }}

              onApprove={async (data, actions) => {
                try {
                  const captureData = new FormData();
                  captureData.append('user_id', props.userId);
                  captureData.append('session_id', props.sessionId);
                  captureData.append('order_id', data.orderID);

                  const response = await fetch(configData.API_URL + "/payment/paypal/capture-order/", {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                      'X-CSRFToken': props.csrf
                    },
                    body: captureData
                  });

                  const details = await response.json();
                  // Three cases to handle:
                  //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  //   (2) Other non-recoverable errors -> Show a failure message
                  //   (3) Successful transaction -> Show confirmation or thank you message

                  // This example reads a v2/checkout/orders capture response, propagated from the server
                  // You could use a different API or structure for your 'orderData'
                  const errorDetail = Array.isArray(details.details) && details.details[0];
                
                  if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                    return actions.restart();
                    // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                  }

                  if (errorDetail) {
                    let msg = 'Sorry, your transaction could not be processed.';
                    msg += errorDetail.description ? ' ' + errorDetail.description : '';
                    msg += details.debug_id ? ' (' + details.debug_id + ')' : '';
                    alert(msg);
                  }
                  
                  // Successful capture! For demo purposes:
                  // console.log('Capture result', details, JSON.stringify(details, null, 2));
                  const transaction = details.purchase_units[0].payments.captures[0];
                  // alert('Transaction '+ transaction.status + ': ' + transaction.id + 'See console for all available details');
                  props.onCompleted(true, 'The payment is successful, please continue...');
                } catch (error) {
                  console.error(error);
                  // Handle the error or display an appropriate error message to the user
                }
              }}
          />)
        })
      }

    </div>
  );
}

export default MyPaypal;
