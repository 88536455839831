import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // 使用react-i18next适配器
  .init({
    resources: {
      en: {
        translation: require('./locales/en.json')
      },
      cn: {
        translation: require('./locales/cn.json')
      }
    },
    lng: 'en', // 默认语言
    fallbackLng: 'en', // 当无法找到指定语言时使用的回退语言
    interpolation: {
      escapeValue: false // 避免对翻译文本进行转义
    }
  });

export default i18n;
