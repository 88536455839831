import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import ConfigurationPage from './pages/Configuration';
import PaymentPage from './pages/Payment';
import AdminPage from './pages/Admin';
import MyTutorPage from './pages/MyTutor';
import BankPage from './pages/Bank';


const initialOptions = {
  "client-id": "ARFMkR5YiA6nrd8aKBQDRObBSex2teHN7tRrd087SbV0QTINFZLEBJ-aommppPmLOrAr6c0PVaGvF6V-",
  "currency": "CAD",
  // "enable-funding": "paylater,venmo",
};

function App() {
  return (
    <PayPalScriptProvider options={initialOptions}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MyTutorPage />} />
        <Route path="/mytutor" element={<MyTutorPage />} />
        <Route path='/bank' element={<BankPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/account' element={<ConfigurationPage />} />
        <Route path='/payment' element={<PaymentPage />} />
        <Route path='/admin' element={<AdminPage />} />
      </Routes>
    </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
